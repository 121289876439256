var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parent_height" },
    [
      !_vm.isShowEdit
        ? _c(
            "el-container",
            [
              _c("CommonTree", {
                ref: "commonTreeCore",
                staticClass: "commonTreeCore",
                attrs: {
                  treeTitle: "区域分类",
                  treeData: _vm.treeData,
                  nodeKey: "id",
                  searchTitle: "typeName",
                  defaultProps: { label: "typeName" },
                  isShowdig:
                    this.$route.query.type != "view" && !this.dialogType,
                  expandOnClickNode: false,
                },
                on: {
                  getTreeAdd: _vm.getTreeAdd,
                  getTreeEdit: _vm.getTreeEdit,
                  getTreeDelete: _vm.getTreeDelete,
                  getNodeClick: _vm.handleNodeClick,
                },
              }),
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": "项目区域",
                      showIcon: false,
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-add-tabs": _vm.headAdd,
                      "head-remove": _vm.headRemove,
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.searchColumns },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    staticClass: "itemListGrid",
                    attrs: {
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      "grid-row-btn": _vm.gridRowBtn,
                    },
                    on: {
                      "page-current-change": _vm.handleCurrentChange,
                      "page-size-change": _vm.handleSizeChange,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "row-edit": _vm.rowEdit,
                      "row-remove": _vm.rowRemove,
                      "row-change": _vm.rowChange,
                      "row-down": _vm.rowDown,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "areaCode",
                          fn: function ({ row, index }) {
                            return [
                              _vm.type == "view"
                                ? _c("span", [_vm._v(_vm._s(row.areaCode))])
                                : _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.areaCode))]
                                  ),
                            ]
                          },
                        },
                        {
                          key: "codeUrl",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { padding: "5px 0" } },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                      "vertical-align": "middle",
                                    },
                                    attrs: {
                                      src: `data:image/png;base64,${row.baseCode.codeUrl}`,
                                      "preview-src-list": [
                                        `data:image/png;base64,${row.baseCode.codeUrl}`,
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1573548894
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "区域分类",
            "before-close": _vm.handleTreeCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("avue-form", {
            ref: "cedForm",
            attrs: { option: _vm.treeDialogOption },
            on: { submit: _vm.handleSubmit },
            model: {
              value: _vm.treeForm,
              callback: function ($$v) {
                _vm.treeForm = $$v
              },
              expression: "treeForm",
            },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTreeCancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTreeNodeSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.isShowEdit
        ? _c("projectAreaAdd", {
            ref: "projectAreaAdd",
            staticClass: "parent_height",
            attrs: {
              prjId: _vm.prjId,
              typeId: _vm.typeId,
              areaId: _vm.areaId,
              deptSegment: _vm.deptSegment,
            },
            on: { callback: _vm.handleCallback },
          })
        : _vm._e(),
      _c("qrcodeDialog", { ref: "qrcodeDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }