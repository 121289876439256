<template>
  <div class="areaCheckRecord">
    <!-- 项目区域-检查记录 -->
    <el-container class="areaCheckRecord_container">
      <el-main>
        <head-layout
          head-title="检查记录"
          :showIcon="false"
          :head-btn-options="headBtnOptions"
          @head-add="headAdd"
        />
        <!--  -->
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @grid-row-detail-click="rowEdit($event,'view')"
        >
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
 import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import {
    prjAreaCheckPage,
    prjAreaCheckRecordAdd
  } from '@/api/safe/projectArea.js'
  import { getList } from "@/api/check/checkTasks";
  import { mapGetters } from "vuex";
  import {dateFormat} from "@/util/date";

  // import {getDictionaryCode} from '@/api/system/dictbiz.js'
  export default {
    components: {
      HeadLayout,
      GridLayout,
    },

    props: {
      areaId: {
        type: String,
        default: ''
      },
    },
  
    data() {
      return {
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        query: {},
        // prjId: "", // 项目id
        selectionList: [],
        areaData: {},
      };
    },
  
    computed: {
      ...mapGetters(["permission", "colorName","userInfo"]),

      headBtnOptions() {
          return [
            {
              label: "新增检查",
              emit: "head-add",
              type: "button",
              icon: "",
            },
          ];
      },
  
      tableOptions() {
        return {
          menu: false,
          selection: false,
          linklabel: 'taskCode',
          column: [
            {
              label: "任务编号",
              prop: "taskCode",
              align: "center",
              overHidden: true,
            },
            {
              label: "检查类型",
              // prop: "checkTypeName",
              prop: "checkType",
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=check_type`,
              props: {
                label: 'dictValue',
                value: 'dictKey',
              },
              align: "center",
              overHidden: true,
            },
            {
              label: "检查日期",
              prop: "checkTime",
              align: "center",
              overHidden: true,
            },
            {
              label: "检查人",
              prop: "nickName",
              align: "center",
              overHidden: true,
            },
            {
              label: "任务状态",
              prop: "taskStatus",
              align: "center",
              overHidden: true,
              dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=CHECK_TASK_STATUS",
              props: {
                value: 'dictKey',
                label: 'dictValue',
              }
            },
          ],
        };
      },
    },
  
    methods: {
      init (data) {
        this.areaData = data
        this.onLoad(this.page)
      },
      /** 右侧表格操作 */
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.areaId = this.areaId || '';
        params.areaCode = this.areaData.areaCode
        getList(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
        ).then((res) => {
          const data = res.data.data;
          // this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total
          this.tableData = data.records;
          this.tableLoading = false;
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.areaCheckRecordGridLayOut.$refs.grid.$refs.table.doLayout();
          });
          this.$refs.areaCheckRecordGridLayOut.selectionClear();
        });
      },
      //页数改变
      handleCurrentChange(e) {
        this.page.currentPage = e.currentPage;
        this.onLoad(this.page);
      },
      //size改变
      handleSizeChange(e) {
        this.page.currentPage = 1;
        this.page.pageSize = e.pageSize;
        this.onLoad(this.page);
      },
      headAdd() {
        const params = {
          areaName: this.areaData.areaName,
          areaId: this.areaData.areaId,
          areaCode: this.areaData.areaCode,
          checkTime: dateFormat(new Date()),
          actId: 0,
          organizationId: this.userInfo.dept_id,
          organizationName: this.userInfo.dept_name
        }
        prjAreaCheckRecordAdd(params).then(res=>{
          console.log('新增',res)
          // let row = {id: res.data.data,}
          this.rowEdit({id: res.data.data}, 'edit')
        })
      },

      rowEdit(row,type){
        this.$router.push({
          path: `/hiddenTrouble/check/checkTasks/edit`,
          query: {
            actId: row.actId,
            id: row.id,
            type,
          }
        })
      },

    },
  };

</script>

<style lang="scss" scoped>
.areaCheckRecord{
  height: 100%;
  .el-main{
      overflow: hidden;
  }
}
::v-deep .areaCheckRecord_container #gridLayout {
  height: calc(100% - 100px) !important;
}
::v-deep .areaCheckRecord_container .avue-crud {
  height: 100% !important;
  .el-card{
      height: 100% !important;
      overflow: hidden;
    .el-card__body  {
      height: 100% !important;
  
      .el-form {
        height: 100% !important;
  
        .el-table {
          height: 100% !important;
          max-height: 100% !important
        }
      }
    }
    // .avue-crud__pagination{
    //     height: 50px !important
    // }

  }
}

</style>
