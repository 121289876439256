<template>
  <div class="checkList_dialog">
    <!-- 项目区域-检查清单 -->
    <el-container style="height: 55vh">
      <CommonTree
        class="checkList_dialog_tree"
        ref="commonTreeCore"
        :treeData="treeData"
        :showTopTip="false"
        nodeKey="id"
        searchTitle="sourceTypeName"
        :defaultProps="{ label: 'sourceTypeName' }"
        :expandOnClickNode="false"
        @getNodeClick="handleNodeClick"
      />
      <el-main>
        <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
          class="checkDialogGridLayOut"
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          @gird-handle-select-click="selectionChange"
          :grid-row-btn="gridRowBtn"
          @row-change="rowChange"
          >
          <!-- :page="page"
          :data-total="page.total"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad" -->
        </grid-layout>
        <div style="display: flex;justify-content: flex-end;border-top: 1px solid #CCCCCC;padding: 5px 12px 0 ">
          <el-button size="mini" @click="closeDialog">取消</el-button>
          <el-button size="mini" type="primary" @click="confirm">确认</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  getRiskSourceTypeTree,
  riskVersionList,
  riskLastVersion,
  getRisksourceList
} from "@/api/riskManage/project";
import { mapGetters } from "vuex";
import projectAreaAdd from "./form.vue";
// import {getDictionaryCode} from '@/api/system/dictbiz.js'
export default {
  components: {
    CommonTree,
    GridLayout,
    projectAreaAdd,
  },

  props: {
    areaId: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      // 左侧树
      treeData: [],
      nodeData: {},
      // end
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      // prjId: "", // 项目id
      selectionList: [],
      searchColumns: [
        {
          label: "",
          prop: "",
          span: 8,
          placeholder: "请输入检查项目/作业步骤",
        },
      ],

    };
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),

    treeOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "typeName",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur"],
              },
            ],
          },
          {
            label: "上级名称",
            prop: "parentId",
            type: "tree",
            disabled: false,
            dicData: this.treeData,
            props: {
              label: "typeName",
              value: "id",
            },
            rules: [
              {
                validator: this.parentRule,
              },
            ],
          },
        ],
      };
    },

    tableOptions() {
      return {
      //   menu:this.$route.query.type != 'view',
        // selection: this.$route.query.type != "view",
        column: [
          {
            label: "检查项目/作业步骤",
            prop: "sourceName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn () {
      return [
      {
        label: '选择',
        emit: "row-change",
        type: "button",
        icon: "",
      },
      ]
    }
  },

  created () {
    this.getTreeData()
  },

  methods: {
    /** 左侧树 */
    getTreeData() {
      this.tableLoading = true;
      // 取最新发布版本的
      // riskLastVersion({status: 3}).then(res => {
      //   console.log('最新版本',res.data)
      // })
      riskLastVersion({status: 3}).then((respon) => {
        let riskVersionId = respon.data.data.id
        getRiskSourceTypeTree({ organizationId: this.userInfo.dept_id,riskVersionId }).then((res) => {
          this.treeData = res.data.data;
          if (this.treeData.length) {
            this.$nextTick(() => {
              this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                this.treeData[0].id
              );
              this.handleNodeClick(this.treeData[0]);
            });
          }
        });
      }).catch(()=>{
        this.tableLoading = false
      })
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.query.parentId = data.id;
      // this.page.currentPage = 1;
      this.onLoad();
    },


    /** 右侧表格操作 */
    onLoad(params = {}) {
      this.tableLoading = true;
      // this.page = page;
      // params.typeId = this.nodeData.id || '';
      getRisksourceList({sourceTypeId: this.nodeData.id,...params}).then((res) => {
        const data = res.data.data;
        // this.$refs.gridLayOut.page.total = data.total;
        // this.page.total = data.total
        this.tableData = data;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    gridHeadSearch(searchForm) {
      // this.page.currentPage = 1;
      // this.onLoad(this.page, searchForm);
      this.onLoad(searchForm);
    },
    // 清空
    gridHeadEmpty() {
      // this.page.currentPage = 1;
      // this.onLoad(this.page);
      this.onLoad()
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    rowChange (row) {
      this.$emit('callback',[row])
    },
    closeDialog () {
      this.$emit('callback')
    },
    confirm () {
      if(this.selectionList.length){
        this.$emit('callback',this.selectionList)
      } else {
        this.$message.warning("请选择检查项")
      }
    }

  },
};
</script>

<style lang="scss" scoped>
::v-deep .checkList_dialog .el-main{
    height: 100% !important;
  }
::v-deep .checkDialogGridLayOut{
    height: calc(100% - 120px) !important;
  }
  ::v-deep .checkList_dialog_tree .el-tree{
  height: calc(100% - 120px) !important;
}
</style>
