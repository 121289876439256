<template>
    <div class="areaCheckList">
      <!-- 项目区域-检查清单 -->
      <el-container>
        <CommonTree
        class="areaCheckListTree"
          ref="commonTreeCore"
          treeTitle="检查项"
          :treeData="treeData"
          nodeKey="id"
          searchTitle="typeName"
          :defaultProps="{ label: 'typeName' }"
          isShowdig
          :expandOnClickNode="false"
          @getTreeAdd="getTreeAdd"
          @getTreeEdit="getTreeEdit"
          @getTreeDelete="getTreeDelete"
          @getNodeClick="handleNodeClick"
        />
        <el-main>
          <head-layout
            head-title="检查清单"
            :showIcon="false"
            :head-btn-options="headBtnOptions"
            @head-add="headAdd"
            @head-remove="headRemove"
          />
          <grid-layout
            class="tabGridLayOut"
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-current-change="handleCurrentChange"
            @page-size-change="handleSizeChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
          >
          </grid-layout>
        </el-main>
      </el-container>
      <!-- 左侧树操作 -->
      <el-dialog
        :visible.sync="dialogVisible"
        title="检查项"
        :before-close="handleTreeCancel"
      >
        <avue-form
          :option="treeOption"
          ref="cedForm"
          v-model="treeForm"
          @submit="handleSubmit"
        ></avue-form>
        <template slot="footer">
          <el-button
            size="small"
            @click="handleTreeCancel()"
            v-loading="treeDialogLoading"
          >
            {{ $t(`cip.cmn.btn.celBtn`) }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            @click="handleTreeNodeSave"
            v-loading="treeDialogLoading"
          >
            {{ $t(`cip.cmn.btn.defBtn`) }}
          </el-button>
        </template>
      </el-dialog>
      <!-- 添加 -->
      <el-dialog title="风险标准库引用" :visible.sync="riskDialogVisible" :before-close="beforeClose">
        <checkListDialog ref="checkListDialog" @callback="handleRisk"/>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import CommonTree from "@/views/components/com_tree/index";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import {
    prjAreaCheckTypeTree, 
    prjAreaCheckTypeSubmit, 
    prjAreaCheckTypeRemove, 
    prjAreaCheckPage,
    prjAreaCheckSubmit,
    prjAreaCheckRemove
  } from '@/api/safe/projectArea.js'

  import { mapGetters } from "vuex";
  import projectAreaAdd from "./form.vue";
  import checkListDialog from './checkListDialog.vue';
  export default {
    components: {
      CommonTree,
      HeadLayout,
      GridLayout,
      projectAreaAdd,
      checkListDialog
    },

    props: {
      areaId: {
        type: String,
        default: ''
      },
    },
  
    data() {
      return {
        // 左侧树
        treeData: [],
        nodeData: {},
        dialogVisible: false,
        treeForm: {},
        treeDialogLoading: false,
        // end
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        query: {},
        // prjId: "", // 项目id
        selectionList: [],
        riskDialogVisible: false,
        treeParentData: []
        
      };
    },
  
    computed: {
      ...mapGetters(["permission", "colorName"]),

      treeOption() {
        return {
          size: "small",
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 120,
          column: [
            {
              label: "分类名称",
              prop: "typeName",
              maxlength: 20,
              rules: [
                {
                  required: true,
                  message: "请输入分类名称",
                  trigger: ["blur"],
                },
              ],
            },
            {
              label: "上级名称",
              prop: "parentId",
              type: "tree",
              disabled: false,
              dicData: this.treeParentData,
              props: {
                label: "typeName",
                value: "id",
              },
              rules: [
                {
                  validator: this.parentRule,
                },
              ],
            },
          ],
        };
      },

      headBtnOptions() {
        if (this.$route.query.type != "view") {
          return [
            {
              label: "添加",
              emit: "head-add",
              type: "button",
              icon: "",
            },
            {
              label: this.$t("cip.cmn.btn.delBtn"),
              emit: "head-remove",
              type: "button",
              icon: "",
            },
          ];
        }
      },
  
      tableOptions() {
        return {
          menu: false,
          selection:  true,
          column: [
            {
              label: "危险源描述",
              prop: "riskName",
              width: 300,
              align: "left",
              overHidden: true,
            },
            {
              label: "事故类型",
              prop: "event",
              align: "center",
              type: "select",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl:
                "/api/sinoma-system/dict-biz/dictionary?code=RISK_EVENT_TYPE",
              overHidden: true,
            },
            {
              label: "现有控制措施",
              children: [
                {
                  label: "工程技术",
                  width: 300,
                  align: "left",
                  overHidden: true,
                  prop: "treatEngineer",
                },
                {
                  label: "管理措施",
                  width: 300,
                  align: "left",
                  overHidden: true,
                  prop: "treatManagment",
                },
                {
                  label: "培训教育",
                  width: 300,
                  align: "left",
                  overHidden: true,
                  prop: "treatTraining",
                },
                {
                  label: "个体防护",
                  width: 300,
                  align: "left",
                  overHidden: true,
                  prop: "treatProtection",
                },
                {
                  label: "应急处置",
                  width: 300,
                  align: "left",
                  overHidden: true,
                  prop: "treatEmergency",
                },
              ],
            },
            // {
            //   label: "风险点及分类",
            //   prop: "sourceName",
            //   align: "center",
            //   overHidden: true,
            // },
            // {
            //   label: "危险源描述",
            //   prop: "riskName",
            //   align: "center",
            //   overHidden: true,
            // },
            // {
            //   label: "管控措施",
            //   prop: "treat",
            //   align: "center",
            //   overHidden: true,
            // },
            // {
            //   label: "隐患排查内容",
            //   prop: "seekContent",
            //   align: "center",
            //   overHidden: true,
            // },
            // {
            //   label: "负责人员",
            //   prop: "dutyUserName",
            //   align: "center",
            //   overHidden: true,
            //   formatter: (value,column) =>{
            //     return column!=-1 ? column : ''
            //   }
            // },
          ],
        };
      },
    },
  
    methods: {
      init () {
        this.getTreeData()
      },
      /** 左侧列表操作 */
      getTreeData() {
        prjAreaCheckTypeTree({ prjAreaId: this.areaId }).then((res) => {
          this.treeData = res.data.data;
          if (this.treeData.length) {
            this.$nextTick(() => {
              this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                this.treeData[0].id
              );
              this.handleNodeClick(this.treeData[0]);
            });
          }
        });
      },
      handleNodeClick(data, node) {
        this.nodeData = data;
        this.query.parentId = data.id;
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      getTreeAdd() {
        this.treeParentData = this.treeData;
        this.treeForm.prjAreaId = this.areaId
        this.treeForm.parentId = this.nodeData.id || '0';
        this.dialogVisible = true;
      },
      getTreeEdit() {
        const data = JSON.parse(JSON.stringify(this.treeData));
        const { typeName, id, parentId, prjAreaId } = this.nodeData;
        this.getParentData(data, id);
        this.treeForm = { typeName, id, parentId, prjAreaId };
        this.dialogVisible = true;
      },
      getParentData(data, id) {
        const arr = data.map((val, index) => {
          if (val.id == id) {
            data.splice(index, 1);
          } else if (val.children) {
            this.getParentData(val.children, id);
            return val;
          } else {
            return val;
          }
        });
        this.treeParentData = arr;
      },
      getTreeDelete(data, done) {
        if (data.children && data.children.length) {
          this.$message({
            type: "warning",
            message: "当前分类下有子级，禁止删除",
          });
          return;
        }
        if(!this.nodeData.id){
          this.$message.warning("请选择操作数据")
        }
        this.$confirm("确认删除数据", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            prjAreaCheckTypeRemove(this.nodeData.id).then((res) => {
              if (res.data.code == 200) {
                this.getTreeData();
                this.$message.success("操作成功");
              }
            });
          })
          .catch(() => {});
      },
      handleTreeCancel() {
        this.$refs.cedForm.resetFields();
        this.dialogVisible = false;
      },
      handleSubmit(data, done) {
        this.$refs.cedForm.validate((valid) => {
          if (valid) {
            this.treeDialogLoading = true;
            prjAreaCheckTypeSubmit(this.treeForm)
              .then(() => {
                this.getTreeData();
                this.$message.success("操作成功");
                this.treeDialogLoading = false;
                this.handleTreeCancel();
                done();
              })
              .catch((err) => {
                done();
              })
              .finally(() => {
                this.treeDialogLoading = false;
              });
          }
        });
      },
      handleTreeNodeSave() {
        if (!this.treeForm.parentId) this.treeForm.parentId = "0";
        this.$refs.cedForm.submit();
      },

      parentRule(rule, value, callback) {
        if (value === "" || !this.treeForm.id) {
          callback();
        } else if (value == this.treeForm.id) {
          callback(new Error("不能选择自己作为上级!"));
        } else {
          callback();
        }
      },

      /** 右侧表格操作 */
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.typeId = this.nodeData.id || '';
        prjAreaCheckPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
        ).then((res) => {
          const data = res.data.data;
          // this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total
          this.tableData = data.records;
          this.tableLoading = false;
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          });
          this.$refs.gridLayOut.selectionClear();
        });
      },
      //页数改变
      handleCurrentChange(e) {
        this.page.currentPage = e.currentPage;
        this.onLoad(this.page);
      },
      //size改变
      handleSizeChange(e) {
        this.page.currentPage = 1;
        this.page.pageSize = e.pageSize;
        this.onLoad(this.page);
      },
      // 选择列表数据
      selectionChange(list) {
        this.selectionList = list;
      },
      // 批量删除
      headRemove() {
        let selectionList = this.$refs.gridLayOut.selectionList;
        if (selectionList.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
          return;
        }
        let ids = selectionList.map(val=>val.id).toString()
        this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: "warning",
        })
          .then(() => {
            prjAreaCheckRemove(ids).then(() => {
              this.onLoad(this.page);
              this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"))
            })
          });
      },

      

      headAdd() {
        // if(this.nodeData.id){
          this.riskDialogVisible = true
          this.$nextTick(()=>{
            this.$refs.checkListDialog.getTreeData()
          })
        // } else {
        //   this.$message.warning("请先选择左侧分类")
        // }
      },

      handleRisk (data) {
        if(!data){
          this.riskDialogVisible = false
          return
        }
        let params = data.map(val => {
          return {
            sourceId: val.id,
          }
        })
        let prjAreaId = this.areaId
        let parentTypeId = this.nodeData.id || ''
        prjAreaCheckSubmit(params,prjAreaId,parentTypeId).then(res => { 
          this.page.currentPage = 1
          this.onLoad(this.page)
          this.riskDialogVisible = false
        })
      },

      beforeClose () {
        this.handleRisk()
      },

      spanMethod (row,column,rowIndex,columnIndex) {
        console.log(row,column,rowIndex,columnIndex)
      }

    },
  };
  </script>
  
  <style lang="scss" scoped>
  .areaCheckList{
    height: 100%;
    .el-main{
        overflow: hidden;
    }
  }
  ::v-deep .tabGridLayOut {
    height: calc(100% - 100px) !important;
  }
  ::v-deep .avue-crud {
    height: 100% !important;
    .el-card{
        height: 100% !important;
        overflow: hidden;
      .el-card__body  {
        height: 100% !important;
    
        .el-form {
          height: 100% !important;
    
          .el-table {
            height: 100% !important;
            max-height: 100% !important
          }
        }
      }
    }
  }
  ::v-deep .areaCheckListTree .el-tree{
    height: calc(100% - 120px) !important;
   }

  </style>
  